import { Document } from '@contentful/rich-text-types';
import { SetOptional } from 'type-fest';

import { LocalizationKey } from 'constants/i18n.messages';

export enum NotificationType {
  Alert = 'Alert',
  MajorNotice = 'MajorNotice',
  MinorNotice = 'MinorNotice',
}

interface NotificationBase {
  readonly id: string;
  readonly link?: string;
  readonly type: keyof typeof NotificationType;

  /** Notification will be only displayed on a specific URL path. */
  readonly path?: string;

  /** Only display once and will be cleared when the user navigates away. */
  readonly showOnlyOnce?: boolean;
  readonly autoDismissAfterSeconds?: number;
}

export type NotificationBody = string | Document;

export interface ExternalNotification extends NotificationBase {
  readonly id: string;
  readonly title: string;
  readonly body: string;
}

export enum InternalNotificationType {
  PAYMENT = 'payment',
  BOOKING = 'booking',
  GENERAL = 'general',
}

export enum InternalNotificationTestId {
  PAYMENT_CANCELLED = 'notice.payment.paymentCancelled',
  PAYMENT_INVALID_REQUEST = 'notice.payment.transactionIdMissing',
  PAYMENT_FAILED = 'notice.payment.paymentFailed',
  JOURNEY_CANCELLATION_SUCCESS = 'notice.journey.canellation.success',
  JOURNEY_CANCELLATION_FAILED = 'notice.journey.cancellation.failed',
  // VEHICLE_CANCELLATION_SUCCESS = 'notice.vehicle.canellation.success',
  // VEHICLE_CANCELLATION_FAILED = 'notice.vehicle.cancellation.failed',
  USER_DETAILS_FETCH_FAILED = 'notice.user.details.failed',
}

export interface InternalNotification extends NotificationBase {
  readonly titleId?: LocalizationKey;
  readonly titleParams?: { [key: string]: string };
  readonly bodyId: LocalizationKey;
  readonly bodyParams?: { [key: string]: string };
  readonly labelKey?: LocalizationKey;
  readonly internalType: InternalNotificationType;

  // used to identify different use-case specific notices in e2e testing
  ['data-testid']?: InternalNotificationTestId;
}

export type InternalNotificationConfig = SetOptional<InternalNotification, 'id'>;
