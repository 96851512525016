import classNames from 'classnames';
import { ForwardedRef, HTMLAttributes, createElement, forwardRef } from 'react';

import styles from './Container.module.css';

type Props = {
  element?: keyof JSX.IntrinsicElements;
} & HTMLAttributes<HTMLDivElement>;

const ContainerComponent = (
  { element, className, ...props }: Props,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return createElement(element || 'div', {
    ...props,
    ref,
    className: classNames(styles.container, className),
  });
};

export const Container = forwardRef(ContainerComponent);

Container.displayName = 'Container';
