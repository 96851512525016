import classNames from 'classnames';
import { HTMLAttributes } from 'react';

import { ColorEnum } from '@vrfi/web-components';

import { Container } from 'components/ui/grid';

import styles from './ContentWrapper.module.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  backgroundColor?: string;
  fullWidth?: boolean;
}

const FullWidthWrapper = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={classNames(styles.fullWidth, className)} {...props} />
);

export const ContentWrapper = ({ children, backgroundColor, id, fullWidth, ...rest }: Props) => {
  const Wrapper = fullWidth ? FullWidthWrapper : Container;
  return (
    <div
      className={classNames(styles.wrapper, {
        [styles.blueLinks]: backgroundColor === ColorEnum.grey10,
      })}
      style={{ backgroundColor }}
      id={id}
    >
      <Wrapper {...rest}>{children}</Wrapper>
    </div>
  );
};
