import classNames from 'classnames';
import { ForwardedRef, HTMLAttributes, forwardRef } from 'react';

import styles from './Row.module.css';

const RowComponent = (
  { className, children, ...props }: HTMLAttributes<any>,
  ref: ForwardedRef<HTMLDivElement>,
) => {
  return (
    <div className={classNames(styles.row, className)} ref={ref} {...props}>
      {children}
    </div>
  );
};

export const Row = forwardRef(RowComponent);

Row.displayName = 'Row';

export const rowClass = styles.row;
