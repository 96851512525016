import { LineItemProduct } from '@vrfi/payment-summary';
import { AttributeCode } from '@vrfi/service-attribute-mapper';

import { CabinType, PassengerType, SeatProduct } from 'backend/types.codegen';

/**
 * @deprecated Not really deprecated, but use useMaxPassengers until small groups feature is live
 */
export const MAX_PASSENGERS = 18;
export const SMALL_GROUP_LOWER_LIMIT = 9;

export const PASSENGER_SORT_PRIORITY: Record<PassengerType, number> = {
  [PassengerType.VrEmployee]: 1,
  [PassengerType.PlEmployee]: 1,
  [PassengerType.TramEmployee]: 1,
  [PassengerType.VrEmployeeFamilyMember]: 2,
  [PassengerType.Adult]: 3,
  [PassengerType.Pensioner]: 4,
  [PassengerType.VrPensioner]: 5,
  [PassengerType.Child]: 6,
  [PassengerType.Student]: 7,
  [PassengerType.Conscript]: 8,
  [PassengerType.ConscriptContract]: 9,
  [PassengerType.FdfContract]: 10,
  [PassengerType.Assistant]: 11,

  // Some random passenger types
  [PassengerType.Fip_50Card]: 1000,
  [PassengerType.FipFreeCoupon]: 1000,
  [PassengerType.InterrailEurail_1St]: 1000,
  [PassengerType.InterrailEurail_2Nd]: 1000,
  [PassengerType.Replacement]: 1000,
  [PassengerType.SpecialFreePass]: 1000,
};

export const SELECTABLE_PASSENGER_TYPES: readonly PassengerType[] = [
  PassengerType.Adult,
  PassengerType.Child,
  PassengerType.Student,
  PassengerType.Conscript,
  PassengerType.Pensioner,
];

// These are passenger types that are valid but not show in the passenger select modal
export const UNSELECTABLE_PASSENGER_TYPES: readonly PassengerType[] = [
  PassengerType.VrEmployeeFamilyMember,
  PassengerType.FdfContract,
  PassengerType.Assistant,
];

export const SELECTABLE_PASSENGER_TYPES_VR_DIRECT_EMPLOYEE: readonly PassengerType[] = [
  PassengerType.VrEmployee,
  PassengerType.VrEmployeeFamilyMember,
  ...SELECTABLE_PASSENGER_TYPES,
];

export const SELECTABLE_PASSENGER_TYPES_PL_EMPLOYEE: readonly PassengerType[] = [
  PassengerType.PlEmployee,
  ...SELECTABLE_PASSENGER_TYPES,
];

export const SELECTABLE_PASSENGER_TYPES_TRAM_EMPLOYEE: readonly PassengerType[] = [
  PassengerType.TramEmployee,
  ...SELECTABLE_PASSENGER_TYPES,
];

export const BED_CABIN_TYPES = new Set([
  CabinType.BerthCabinEdm,
  CabinType.BerthCabinWithShower,
  CabinType.BerthCabinCemt,
]);

export const BREAKFAST_PRODUCTS: string[] = [
  LineItemProduct.NightTrainBreakfastA1,
  LineItemProduct.NightTrainBreakfastA2,
  LineItemProduct.NightTrainBreakfastA3,
  LineItemProduct.NightTrainBreakfastA4,
  LineItemProduct.NightTrainBreakfastA5,
];

export const MEALS_PER_PASSENGER = 3;

export const GROUP_SALES_AVECRA_DEADLINE_IN_DAYS = 14;

export const SINGLE_SEAT_UPGRADES: Array<{ from: SeatProduct; to: SeatProduct }> = [
  {
    from: SeatProduct.EcoClassSeat,
    to: SeatProduct.SingleSeatEco,
  },
  {
    from: SeatProduct.SeatUpstairsRestaurantWagon,
    to: SeatProduct.SingleSeatUpstairsRestaurantWagon,
  },
  {
    from: SeatProduct.ExtraClassSeat,
    to: SeatProduct.SingleExtraClassSeat,
  },
];

export const PET_SEAT_ATTRIBUTES: string[] = [AttributeCode.PET, AttributeCode.LARGE_PET];

export const PET_SECTION_SEAT_ATTRIBUTES: string[] = [
  AttributeCode.COACH_WITH_PETS,
  AttributeCode.COACH_WITH_PETS_OPPOSITE,
  AttributeCode.PET_SECTION_OPPOSITE_PENDO,
];
