import { getValue } from './object';

export const isNonNullable = <T>(item: T): item is NonNullable<T> =>
  item !== null && item !== undefined;

export const isUniq = <T>(value: T, index: number, self: T[]) => self.indexOf(value) === index;

export const groupBy = <T extends object>(arr: T[], ...props: (keyof T)[]): Record<string, T[]> => {
  return arr.reduce((acc, obj) => {
    const key = props.map((prop) => obj[prop]).join('-');
    acc[key] = acc[key] || [];
    acc[key].push(obj);
    return acc;
  }, {} as Record<string, T[]>);
};

export const castArray = <T>(value: T | T[]): T[] => (Array.isArray(value) ? value : [value]);

export const emptyArray = Object.freeze([]) as [];

export const pick = <T extends Record<string, any>>(arr: T[], key: keyof T) =>
  arr.map((item) => item[key]);

/**
 * @returns values which are in both arrays
 */
export const intersection = <T>(a: T[], b: T[]) =>
  a.filter((item) => b.includes(item)).filter(isUniq);

/**
 * @returns values which are in the first array but not in the second array
 */
export const difference = <T>(first: T[], second: T[]): T[] =>
  first.filter((item) => !second.includes(item));

/**
 * @returns values which are in the first array but not in the second array. Values are compared using given comparator function
 */
export const differenceWith = <T>(first: T[], second: T[], fn: (a: T, B: T) => boolean): T[] =>
  first.filter((a) => second.every((b) => !fn(a, b)));

/**
 * @returns values which are present only on the other array
 */
export const symmetricDifference = <T>(first: T[], second: T[]): T[] => [
  ...difference(first, second),
  ...difference(second, first),
];

/**
 * @example range(1,3) // [1, 2, 3]
 */
export const range = (start: number, end: number) =>
  Array(end - start + 1)
    .fill(true)
    .map((_, i) => start + i);

export const join = <T, J>(array: T[], joiner: J): Array<T | J> =>
  array.flatMap((item, index) => [item, ...(index + 1 < array.length ? [joiner] : [])]);

export const countValues = <T extends string>(array: T[]) => {
  const counts = array.reduce((acc, value) => {
    acc[value] = (acc[value] || 0) + 1;
    return acc;
  }, {} as Record<T, number>);

  return new Proxy(counts, {
    get(target, p) {
      return getValue(target, p) || 0;
    },
  });
};

export const getUniformValue = <T>(array: T[]) =>
  array.reduce((acc, value) => (acc === value ? acc : undefined), array.at(0));
