import { Locale } from 'date-fns';
import { enUS, fi, sv } from 'date-fns/locale';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { LOCALE } from 'constants/i18n';

import { formatDateFinnishTZ } from 'utils/date';
import { formatPlainDate } from 'utils/plainDate';

const locales: Record<LOCALE, Locale> = {
  [LOCALE.Fi]: fi,
  [LOCALE.En]: enUS,
  [LOCALE.Sv]: sv,
};

/**
 * Internal JS representation of Date is always in UTC.
 * For displaying, we need to transpose the dates back to the finnish time zone.
 */
export const useFormatDate = () => {
  const { locale } = useIntl();
  return useCallback<typeof formatDateFinnishTZ>(
    (date, format, options) =>
      formatDateFinnishTZ(date, format, { locale: locales[locale], ...options }),
    [locale],
  );
};

export const useFormatPlainDate = () => {
  const { locale } = useIntl();
  return useCallback<typeof formatPlainDate>(
    (plainDate, format, options) =>
      formatPlainDate(plainDate, format, { locale: locales[locale], ...options }),
    [locale],
  );
};
