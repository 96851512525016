'use client';
import { ReactElement, ReactNode, useMemo } from 'react';
import { FormattedNumber, useIntl } from 'react-intl';

import { LocalCase, toLocalCase } from '@vrfi/finnish-tools';

import { PassengerType, Train, VehicleType } from 'backend/types.codegen';

import LocalizedMessage from 'components/i18n/LocalizedMessage';

import {
  DATE_FORMAT,
  DAY_AND_MONTH_FORMAT,
  SHORT_WEEKDAY_AND_DATE_FORMAT,
  TIME_FORMAT,
  WEEKDAY_AND_DATE_FORMAT,
  WEEKDAY_AND_DATE_FORMAT_SHORT,
} from 'constants/date';
import { CURRENCY_OPTIONS, LOCALE } from 'constants/i18n';
import {
  ContentPagesMessage,
  GenericMessage,
  ReceiptTypeMessage,
  UnitMessage,
} from 'constants/i18n.messages';

import { useGetStation } from 'hooks/stations/useGetStation';
import { useFormatDate, useFormatPlainDate } from 'hooks/useFormatDate';

import { withStationsProvider } from 'providers/station';

import { isNumber, Maybe } from 'types/utils';

import {
  getLineItemProductMessageId,
  getPassengerTypeMessageId,
  getTrainTypeMessageId,
  getVehicleTypeMessageId,
  seriesTicketProductMap,
} from 'utils/messages';
import { PlainDate } from 'utils/plainDate';
import { capitalize as capitalizeStr, nonBreakingSpace } from 'utils/string';
import { daysToMonths } from 'utils/time';

import styles from './LocalizedMessages.module.css';

interface CommonProps {
  count?: number;
  children?: (...formattedMessage: ReadonlyArray<string | ReactNode>) => ReactNode;
  lowercase?: boolean;
  capitalize?: boolean;
}

export const LocalizedShortDateWithWeekdayAndYear = ({
  value,
  capitalize,
}: {
  value: Date | string;
  capitalize?: boolean;
}) => {
  const { locale } = useIntl();
  const formatDate = useFormatDate();
  const str = formatDate(value, SHORT_WEEKDAY_AND_DATE_FORMAT[locale]);
  return <span>{capitalize ? capitalizeStr(str) : str}</span>;
};
LocalizedShortDateWithWeekdayAndYear.displayName = 'LocalizedShortDateWithWeekdayAndYear';

type LocalizedProps = {
  value: string;
  children?: (value: string) => ReactElement;
};

export const LocalizedTime = ({ value, children }: LocalizedProps) => {
  const formatDate = useFormatDate();
  const msg = formatDate(value, TIME_FORMAT);

  return children ? children(msg) : <>{msg}</>;
};

/**
 * Renders localized time strings like 'kello 12:15 in a span'
 */
export const LocalizedTimeWithClockLiteral = ({ value, children }: LocalizedProps) => {
  const { formatMessage } = useIntl();
  const formatDate = useFormatDate();
  const msg = formatDate(
    value,
    `'${formatMessage({ id: ContentPagesMessage.CLOCK })}' ${TIME_FORMAT}`,
  );

  return children ? children(msg) : <span>{msg}</span>;
};

export const LocalizedDateTime = ({ value }: { value: string }) => {
  const { formatMessage } = useIntl();
  const formatDate = useFormatDate();

  const format = `${DATE_FORMAT} '${formatMessage({
    id: ContentPagesMessage.CLOCK,
  })}' ${TIME_FORMAT}`;

  return <time dateTime={value}>{formatDate(value, format)}</time>;
};

export const LocalizedDate = ({
  value,
  format = DATE_FORMAT,
}: {
  value: string | Date;
  format?: string;
}) => {
  const formatDate = useFormatDate();
  return <>{formatDate(value, format)}</>;
};

export const LocalizedPlainDateWithWeekday = ({
  value,
  capitalize,
  shortDayFormat,
}: {
  value: PlainDate;
  capitalize?: boolean;
  shortDayFormat?: boolean;
}) => {
  const { locale } = useIntl();
  const shouldCapitalize = capitalize || locale === 'en';
  const formatPlainDate = useFormatPlainDate();
  const date = formatPlainDate(
    value,
    shortDayFormat ? WEEKDAY_AND_DATE_FORMAT_SHORT[locale] : WEEKDAY_AND_DATE_FORMAT[locale],
  );

  return <>{shouldCapitalize ? capitalizeStr(date) : date}</>;
};

export const LocalizedPlainDateWithDayAndMonth = ({ value }: { value: PlainDate }) => {
  const { locale } = useIntl();
  const formatPlainDate = useFormatPlainDate();
  return formatPlainDate(value, DAY_AND_MONTH_FORMAT[locale]);
};

type SignDisplay = NonNullable<Parameters<typeof FormattedNumber>[0]['signDisplay']>;

export const LocalizedCurrency = ({
  value,
  children,
  signDisplay,
}: {
  value: number;
  children?: (value: string) => JSX.Element;
  signDisplay?: SignDisplay;
}) => (
  <span className={styles.localizedCurrency}>
    <FormattedNumber {...CURRENCY_OPTIONS} signDisplay={signDisplay} value={value / 100}>
      {children}
    </FormattedNumber>
  </span>
);
LocalizedCurrency.displayName = 'LocalizedCurrency';

export const LocalizedSeriesTicketProduct = ({
  name,
  children,
}: {
  name: string;
  children?: (value: string) => JSX.Element | string;
}) => {
  const { formatMessage } = useIntl();
  const id = seriesTicketProductMap[name];
  const msg = id ? formatMessage({ id }) : null;

  if (!msg) {
    return null;
  }

  return <>{children ? children(msg) : msg}</>;
};

export const LocalizedPassengerType = ({
  type,
  count,
  lowercase,
  capitalize,
  children,
}: {
  type: PassengerType;
} & CommonProps) => (
  <LocalizedMessage
    id={getPassengerTypeMessageId(type)}
    lowercase={!!lowercase}
    capitalize={!!capitalize}
    {...(count !== undefined
      ? {
          pluralId: getPassengerTypeMessageId(type, 2),
          count: count,
        }
      : { count: undefined })}
  >
    {children}
  </LocalizedMessage>
);
LocalizedPassengerType.displayName = 'LocalizedPassengerType';

export const LocalizedVehicleType = ({
  type,
  lowercase,
  capitalize,
  children,
}: {
  type: VehicleType;
} & Omit<CommonProps, 'count'>) => (
  <LocalizedMessage
    id={getVehicleTypeMessageId(type)}
    lowercase={!!lowercase}
    capitalize={!!capitalize}
  >
    {children}
  </LocalizedMessage>
);
LocalizedVehicleType.displayName = 'LocalizedVehicleType';

export const LocalizedPassengerCount = ({
  type,
  count,
  lowercase,
  capitalize,
}: {
  type: PassengerType;
} & CommonProps) => (
  <LocalizedPassengerType
    type={type}
    count={count}
    lowercase={!!lowercase}
    capitalize={!!capitalize}
  >
    {(text) => `${count} ${text}`}
  </LocalizedPassengerType>
);
LocalizedPassengerCount.displayName = 'LocalizedPassengerCount';

export const LocalizedTrainName = ({
  train: { type, label, commercialLineId },
}: {
  train: Pick<Train, 'type' | 'label'> & { commercialLineId?: Maybe<string> };
}) => {
  const { formatMessage } = useIntl();
  const localizedTrainType = formatMessage({ id: getTrainTypeMessageId(type) });
  const trainNumber = type !== 'HLA' ? (commercialLineId ? commercialLineId : label) : '';

  return `${localizedTrainType} ${trainNumber}`;
};
LocalizedTrainName.displayName = 'LocalizedTrainName';

export const LocalizedFloorName = ({ floor }: { floor: number | string }) =>
  (isNumber(floor) && floor > 1) || floor === 'UPSTAIRS' || floor === 'up' ? (
    <LocalizedMessage id={GenericMessage.FLOOR_UPSTAIRS} />
  ) : (
    <LocalizedMessage id={GenericMessage.FLOOR_DOWNSTAIRS} />
  );

LocalizedFloorName.displayName = 'LocalizedFloorName';

interface LocalizedStationProps {
  fallback?: string;
  station: string;
  localCase?: LocalCase;
  readonly children?: (...formattedMessage: ReadonlyArray<string | ReactNode>) => ReactNode;
}

const LocalizedStationImpl = ({
  station,
  fallback,
  localCase,
  children,
}: LocalizedStationProps) => {
  const { locale } = useIntl();
  const getStation = useGetStation();

  const stationName = useMemo(() => {
    const name = getStation(station)?.name || fallback;
    return name && localCase && locale === LOCALE.Fi ? toLocalCase(localCase)(name) : name;
  }, [fallback, getStation, localCase, locale, station]);

  return <>{children ? children(stationName) : stationName}</>;
};

export const LocalizedStation = withStationsProvider(LocalizedStationImpl);

export const LocalizedProductName = ({
  type,
  attribute,
  count,
}: {
  type: string;
  attribute?: string;
  count?: number;
}) => {
  const id = getLineItemProductMessageId({ type, attribute, count }) ?? ReceiptTypeMessage.UNKNOWN;
  return <LocalizedMessage id={id} />;
};

export const LocalizedMonthSpan = ({ days }: { days: number }) => {
  const months = daysToMonths(days);
  const value = months || days;

  return (
    <LocalizedMessage id={months > 0 ? UnitMessage.MONTH : UnitMessage.DAY} values={{ value }}>
      {(unit) => `${value}${nonBreakingSpace}${unit}`}
    </LocalizedMessage>
  );
};

const FILE_SIZE_UNITS: Record<LOCALE, [string, string, string, string]> = {
  [LOCALE.Fi]: ['t', 'kt', 'Mt', 'Gt'],
  [LOCALE.En]: ['B', 'kB', 'MB', 'GB'],
  [LOCALE.Sv]: ['B', 'kB', 'MB', 'GB'],
};

export const LocalizedFileSize = ({ bytes }: { bytes: number }) => {
  const { locale, formatNumber } = useIntl();

  const exponent = Math.min(
    Math.floor(Math.log(bytes) / Math.log(1024)),
    FILE_SIZE_UNITS[locale].length - 1,
  );
  const value = bytes / Math.pow(1024, exponent);

  return (
    <>
      {formatNumber(value, {
        maximumFractionDigits: exponent > 1 && value < 1000 ? 1 : 0,
      })}{' '}
      {FILE_SIZE_UNITS[locale][exponent]}
    </>
  );
};
