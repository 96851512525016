import { makeVar, useReactiveVar } from '@apollo/client';
import {
  InternalNotification,
  InternalNotificationConfig,
  InternalNotificationType,
  NotificationType,
} from 'components/ui/Notifications/types';
import { useEffect } from 'react';
import { emptyArray } from 'utils/array';
import { generateRandomId } from 'utils/misc';

const NOTIFICATION_ORDER: Record<NotificationType, number> = {
  Alert: 1,
  MajorNotice: 2,
  MinorNotice: 3,
};

const notificationsVar = makeVar<InternalNotification[]>([]);
const modalsWithNotificationsVar = makeVar(0);

export const useNotifications = ({ inModal }: { inModal?: boolean } = {}) => {
  useEffect(() => {
    if (!inModal) {
      return;
    }

    modalsWithNotificationsVar(modalsWithNotificationsVar() + 1);

    return () => {
      modalsWithNotificationsVar(modalsWithNotificationsVar() - 1);
    };
  }, [inModal]);

  const notifications = useReactiveVar(notificationsVar);
  const modalsWithNotifications = useReactiveVar(modalsWithNotificationsVar);

  if (modalsWithNotifications > 0 && !inModal) {
    return emptyArray;
  }

  return notifications;
};

/**
 * @deprecated Don't add new internal notifications, use error/info messages directly on the page
 */
export const addInternalNotification = (notification: InternalNotificationConfig) => {
  notificationsVar(
    [
      ...notificationsVar(),
      {
        id: generateRandomId(),
        autoDismissAfterSeconds:
          notification.type === NotificationType.MinorNotice ? 10 : undefined,
        ...notification,
      },
    ].sort((a, b) => NOTIFICATION_ORDER[a.type] - NOTIFICATION_ORDER[b.type]),
  );
};

export const removeInternalNotificationByType = (type: InternalNotificationType) => {
  notificationsVar(notificationsVar().filter((notification) => notification.internalType !== type));
};

export const removeInternalNotificationById = (id: string) => {
  notificationsVar(notificationsVar().filter((notification) => notification.id !== id));
};

export const removeNotifications = ({
  path,
  clearShowOnceOnly,
}: {
  path: string;
  clearShowOnceOnly: boolean;
}) => {
  notificationsVar(
    notificationsVar().filter(
      (notification) =>
        (clearShowOnceOnly && !notification.showOnlyOnce) || (path && path !== notification.path),
    ),
  );
};
