/* eslint-disable no-restricted-syntax */
import { Maybe, hasDefinedProp, isDate, isString } from 'types/utils';

import { range } from './array';
import { DateFnFormatOptions, createDate, formatDateFinnishTZ, isLeapYear } from './date';
import { accumulateUntilNonZero } from './misc';
import { compareField } from './object';
import { zeroPadLeft } from './string';

export interface PlainDate {
  year: number;
  month: number;
  day: number;
}

export interface PlainTime {
  hour: number;
  minute: number;
}

export type PlainDateTime = PlainDate & PlainTime;

enum Month {
  January = 1,
  February = 2,
  March = 3,
  April = 4,
  May = 5,
  June = 6,
  July = 7,
  August = 8,
  September = 9,
  October = 10,
  November = 11,
  December = 12,
}

export interface PlainDateInterval {
  start: PlainDate;
  end: PlainDate;
}

export enum WeekDay {
  Sunday = 0,
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
}

const weekend = [WeekDay.Saturday, WeekDay.Sunday];

interface WeekOptions {
  weekStartsOn: WeekDay;
}

const daysInMonthNotLeapYear: Record<Month, number> = {
  [Month.January]: 31,
  [Month.February]: 28,
  [Month.March]: 31,
  [Month.April]: 30,
  [Month.May]: 31,
  [Month.June]: 30,
  [Month.July]: 31,
  [Month.August]: 31,
  [Month.September]: 30,
  [Month.October]: 31,
  [Month.November]: 30,
  [Month.December]: 31,
};

export const isDaylightSavingsInFinland = (dt: PlainDateTime): boolean => {
  const dstBegins = {
    ...startOfWeek(
      { ...dt, month: Month.March, day: daysInMonthNotLeapYear[Month.March] },
      { weekStartsOn: WeekDay.Sunday },
    ),
    hour: 3,
    minute: 0,
  };
  const dstEnds = {
    ...startOfWeek(
      { ...dt, month: Month.October, day: daysInMonthNotLeapYear[Month.October] },
      { weekStartsOn: WeekDay.Sunday },
    ),
    hour: 4,
    minute: 0,
  };
  return !isEarlierWithTime(dt, dstBegins) && isLaterWithTime(dstEnds, dt);
};

/**
 * Creates a PlainDate from a Date, representing the year-month-day of the Date in Finnish time zone.
 */
export const createPlainDate = (date?: Maybe<Date | string>): PlainDate => {
  const [year, month, day] = formatDateFinnishTZ(date ?? new Date(), 'y M d')
    .split(' ')
    .map(Number);
  return { year, month, day };
};

export const plainDateFromUnknown = (input: unknown): PlainDate => {
  if (isPlainDate(input)) {
    return input;
  }
  if (isString(input)) {
    const decoded = decodeURIComponent(input);
    if (isValidPlainDateString(decoded)) {
      return plainDateFromString(decoded);
    }
    return createPlainDate(createDate(input));
  }
  if (isDate(input)) {
    return createPlainDate(input);
  }
  return createPlainDate();
};

/**
 * @returns {string} Date string in format 2022-12-31
 */
export const plainDateToString = (d: PlainDate): string =>
  `${d.year}-${zeroPadLeft(2)(d.month)}-${zeroPadLeft(2)(d.day)}`;

const getTimeZoneString = (dt: PlainDateTime): string =>
  isDaylightSavingsInFinland(dt) ? '+03:00' : '+02:00';

const plainTimeStringRegex = /^([0-9]{2}):([0-9]{2})$/;
export const plainTimeFromString = (s: string): PlainTime => {
  const result = plainTimeStringRegex.exec(decodeURIComponent(s));
  if (!result) {
    throw new Error(`Invalid PlainTime string: ${s}`);
  }
  const [, hour, minute] = result;
  return { hour: Number(hour), minute: Number(minute) };
};

export const startOfDay = (dt: PlainDate | PlainDateTime): PlainDateTime => ({
  ...dt,
  hour: 0,
  minute: 0,
});

export const plainDateTimeToString = (dt: PlainDateTime): string =>
  `${plainDateToString(dt)}T${zeroPadLeft(2)(dt.hour)}:${zeroPadLeft(2)(
    dt.minute,
  )}:00.000${getTimeZoneString(dt)}`;

export const plainDateTimeToStringWithoutMs = (dt: PlainDateTime): string =>
  `${plainDateToString(dt)}T${zeroPadLeft(2)(dt.hour)}:${zeroPadLeft(2)(
    dt.minute,
  )}:00${getTimeZoneString(dt)}`;

/**
 * 2022-12-31 or 2022-12
 */
const plainDateStringRegex = /^([0-9]{4})-([0-9]{2})(?:-([0-9]{2}))?$/;

export const isValidPlainDateString = (s: string): boolean =>
  plainDateStringRegex.test(decodeURIComponent(s));

const dropTime = (s: string) => {
  return s.split('T')[0];
};

export const plainDateFromString = (s: string): PlainDate => {
  const result = plainDateStringRegex.exec(decodeURIComponent(dropTime(s)));
  if (!result) {
    throw new Error(`Invalid PlainDate string: ${s}`);
  }
  const [, year, month, day] = result;
  return { year: Number(year), month: Number(month), day: day ? Number(day) : 1 };
};

export const plainDateFromTimestamp = (s: string): PlainDate => {
  return createPlainDate(createDate(s));
};

/**
 * Return Date object at 00:00:00 in UTC zone.
 */
export const plainDateToUTCDate = (d: PlainDate): Date => new Date(plainDateToString(d));

/**
 * Return Date object at 00:00:00 in Finnish time zone.
 */
export const plainDateToFinnishTZDate = (d: PlainDate): Date => {
  const dateTime: PlainDateTime = { ...d, hour: 0, minute: 0 };
  return new Date(plainDateTimeToString(dateTime));
};

/**
 * String formatting function for PlainDate objects.
 * Internally uses the date-fns format library and its syntax for the format string.
 * As such, this will also format hour, minute and second values, even though those are not relevant for PlainDate objects.
 * Hour-min-sec will be returned as 00:00:00.
 */
export const formatPlainDate = (
  d: PlainDate,
  format: string,
  options?: DateFnFormatOptions,
): string => formatDateFinnishTZ(plainDateToFinnishTZDate(d), format, options);

/**
 * Compare function for PlainDate objects, implements the rules required for Array.sort():
 * === 0 -> equal.
 * > 0: b is before a.
 * < 0: a is before b.
 */
export const comparePlainDate = (a: PlainDate, b: PlainDate): number => {
  const compare = compareField(a, b);
  return [compare('year'), compare('month'), compare('day')].reduce<number>(
    accumulateUntilNonZero,
    0,
  );
};

/**
 * Compare function for PlainDateTime objects, implements the rules required for Array.sort():
 * === 0 -> equal.
 * > 0: b is before a.
 * < 0: a is before b.
 */
export const comparePlainDateTime = (a: PlainDateTime, b: PlainDateTime): number => {
  const compare = compareField(a, b);
  return [
    compare('year'),
    compare('month'),
    compare('day'),
    compare('hour'),
    compare('minute'),
  ].reduce<number>(accumulateUntilNonZero, 0);
};

export const isEqual = (a: PlainDate, b: PlainDate) =>
  a.year === b.year && a.month === b.month && a.day === b.day;

// > 0	sort b before a
export const isLater = (a: PlainDate, comparedTo: PlainDate): boolean =>
  comparePlainDate(a, comparedTo) > 0;

// < 0	sort a before b
export const isEarlier = (a: PlainDate, comparedTo: PlainDate): boolean =>
  comparePlainDate(a, comparedTo) < 0;

// <= 0	sort a before b
export const isEarlierOrEqual = (a: PlainDate, comparedTo: PlainDate): boolean =>
  comparePlainDate(a, comparedTo) <= 0;

export const isLaterWithTime = (a: PlainDateTime, comparedTo: PlainDateTime): boolean =>
  comparePlainDateTime(a, comparedTo) > 0;

// < 0	sort a before b
export const isEarlierWithTime = (a: PlainDateTime, comparedTo: PlainDateTime): boolean =>
  comparePlainDateTime(a, comparedTo) < 0;

export const chooseEarlier = (a: PlainDate, b: PlainDate): PlainDate => (isEarlier(a, b) ? a : b);

export const chooseLater = (a: PlainDate, b: PlainDate): PlainDate => (isLater(a, b) ? a : b);

export const comparePlainTime = (a: PlainTime, b: PlainTime): number => {
  const compare = compareField(a, b);
  return [compare('hour'), compare('minute')].reduce<number>(accumulateUntilNonZero, 0);
};

export const firstOfNextMonth = (d: PlainDate): PlainDate => ({
  year: d.month !== 12 ? d.year : d.year + 1,
  month: (d.month % 12) + 1,
  day: 1,
});

export const lastOfPreviousMonth = (d: PlainDate): PlainDate =>
  endOfMonth({
    year: d.month !== 1 ? d.year : d.year - 1,
    month: d.month !== 1 ? d.month - 1 : 12,
    day: 1,
  });

export const startOfMonth = (d: PlainDate): PlainDate => ({ ...d, day: 1 });

export const daysInMonth = ({ year, month }: PlainDate): number =>
  ((daysInMonthNotLeapYear as Record<number, number | undefined>)[month] ?? 0) +
  (month === Month.February && isLeapYear(year) ? 1 : 0);

export const endOfMonth = (d: PlainDate): PlainDate => ({
  ...d,
  day: daysInMonth(d),
});

const defaultWeekOptions: WeekOptions = {
  weekStartsOn: WeekDay.Monday,
};

export const getWeekDay = (d: PlainDate): WeekDay => plainDateToUTCDate(d).getUTCDay();

export const endOfWeek = (
  d: PlainDate,
  { weekStartsOn }: WeekOptions = defaultWeekOptions,
): PlainDate => {
  const currentDay = getWeekDay(d);
  const offset = (weekStartsOn + 6 - currentDay) % 7;
  return addDays(d, offset);
};

export const startOfWeek = (
  d: PlainDate,
  { weekStartsOn }: WeekOptions = defaultWeekOptions,
): PlainDate => {
  const currentDay = getWeekDay(d);
  const offset = (7 - weekStartsOn + currentDay) % 7;
  return subDays(d, offset);
};

export const weekDays = (d: PlainDate, options: WeekOptions = defaultWeekOptions): PlainDate[] => {
  const start = startOfWeek(d, options);
  return new Array(7).fill(start).map((d, i) => addDays(d, i));
};

/**
 * First week of year always contains Jan 4. ISO weeks always start on Monday.
 * Return Monday of week with Jan 4.
 * https://en.wikipedia.org/wiki/ISO_week_date
 */
const firstDayOfISOWeekNumberingYear = (year: number): PlainDate =>
  startOfWeek({ year, month: 1, day: 4 }, { weekStartsOn: WeekDay.Monday });

const numberOfDaysBetween = (a: PlainDate, b: PlainDate): number =>
  Math.round((plainDateToUTCDate(b).valueOf() - plainDateToUTCDate(a).valueOf()) / 86400000);

const getISOWeekNumberingYear = (d: PlainDate): number => {
  const firstOfYear = firstDayOfISOWeekNumberingYear(d.year);
  return isEarlier(d, firstOfYear) ? d.year - 1 : d.year;
};

export const sameWeekDayWithYearOffset = (d: PlainDate, yearOffset: number): PlainDate => {
  if (yearOffset === 0) {
    return { ...d };
  }
  if (yearOffset % 1 !== 0) {
    throw new Error('Year offset should be an integer');
  }
  const year = getISOWeekNumberingYear(d);
  const targetYear = year + yearOffset;
  const firstInTargetYear = firstDayOfISOWeekNumberingYear(targetYear);
  const firstMondayInCurrentYear = firstDayOfISOWeekNumberingYear(year);
  const diffBetween = numberOfDaysBetween(firstMondayInCurrentYear, d);
  return addDays(firstInTargetYear, Math.abs(diffBetween));
};

export const eachMonthOfInterval = ({ start, end }: PlainDateInterval): PlainDate[] => {
  if (isLater(start, end)) {
    return [];
  }
  let temp = startOfMonth(start);
  const monthStarts: PlainDate[] = [];
  while (!isLater(temp, end)) {
    monthStarts.push(temp);
    temp = firstOfNextMonth(temp);
  }
  return monthStarts;
};

/**
 * Gives starts of each week that contains start and end PlainDates, or any week between them.
 * Note that the first PlainDate in the output may be before interval start,
 * if start isn't the first day of the week itself.
 */
export const eachWeekOfInterval = (
  { start, end }: PlainDateInterval,
  options: WeekOptions = defaultWeekOptions,
): PlainDate[] => {
  let temp = { ...start };
  const weekStarts: PlainDate[] = [];
  while (!isLater(temp, end)) {
    temp = startOfWeek(temp, options);
    weekStarts.push(temp);
    temp = addDays(temp, 7);
  }
  return weekStarts;
};

export const eachDayOfInterval = ({ start, end }: PlainDateInterval): PlainDate[] => {
  let temp = { ...start };
  const days: PlainDate[] = [];
  while (!isLater(temp, end)) {
    days.push(temp);
    temp = addDays(temp, 1);
  }
  return days;
};

export const addDays = (d: PlainDate, daysToAdd: number): PlainDate => {
  if (daysToAdd < 0 || daysToAdd % 1 !== 0) {
    throw new Error('Days to add must be a positive integer');
  }
  let toAdd = daysToAdd;
  let temp = { ...d };
  while (toAdd > 0) {
    const maxAddThisIteration = Math.min(toAdd, daysInMonth(temp) - temp.day);
    if (maxAddThisIteration === 0) {
      // Last day of month
      temp = firstOfNextMonth(temp);
      toAdd -= 1;
    } else {
      temp.day += maxAddThisIteration;
      toAdd -= maxAddThisIteration;
    }
  }
  return temp;
};

export const subDays = (d: PlainDate, daysToSubtract: number): PlainDate => {
  if (daysToSubtract < 0 || daysToSubtract % 1 !== 0) {
    throw new Error('Days to subtract must be a positive integer');
  }
  let toSub = daysToSubtract;
  let temp = { ...d };
  while (toSub > 0) {
    if (temp.day === 1) {
      temp = lastOfPreviousMonth(temp);
      toSub -= 1;
    } else {
      const maxSubThisIteration = Math.min(toSub, temp.day - 1);
      temp.day -= maxSubThisIteration;
      toSub -= maxSubThisIteration;
    }
  }
  return temp;
};

export const addMonths = ({ year, month, day }: PlainDate, monthsToAdd: number): PlainDate => {
  if (monthsToAdd % 1 !== 0) {
    throw new Error('Months to add must be an integer');
  }
  const monthShift = (month + monthsToAdd) % 12;
  const newMonth = monthShift < 1 ? 12 + monthShift : monthShift;
  const newYear = year + Math.floor((month - 1 + monthsToAdd) / 12);
  const newDay = Math.min(day, daysInMonth({ year: newYear, month: newMonth, day: 1 }));
  return {
    year: newYear,
    month: newMonth,
    day: newDay,
  };
};

export const addYears = ({ year, month, day }: PlainDate, yearsToAdd: number): PlainDate => {
  if (yearsToAdd % 1 !== 0) {
    throw new Error('Years to add must be an integer');
  }
  const newYear = year + yearsToAdd;
  const newDay = Math.min(day, daysInMonth({ year: newYear, month, day: 1 }));
  return {
    year: newYear,
    month,
    day: newDay,
  };
};

const plainDateFields = ['year', 'month', 'day'];

const isPlainDateType = (x: unknown): x is PlainDate =>
  typeof x === 'object' &&
  !!x &&
  plainDateFields.every((field) => hasDefinedProp(field)(x) && typeof x[field] === 'number');

export const isPlainDate = (x: unknown): x is PlainDate => isPlainDateType(x) && isValid(x);

export const isValid = ({ year, month, day }: PlainDate): boolean =>
  year % 1 === 0 &&
  month % 1 === 0 &&
  day % 1 === 0 &&
  month >= 1 &&
  month <= 12 &&
  day >= 1 &&
  day <= daysInMonth({ year, month, day });

export const isSameMonth = (a: PlainDate, b: PlainDate): boolean => {
  const compare = compareField(a, b);
  return [compare('year'), compare('month')].reduce<number>(accumulateUntilNonZero, 0) === 0;
};

export const isSameWeek = (
  a: PlainDate,
  b: PlainDate,
  options: WeekOptions = defaultWeekOptions,
): boolean => isSameDay(startOfWeek(a, options), startOfWeek(b, options));

export const isSameDay = (a: PlainDate, b: PlainDate): boolean => comparePlainDate(a, b) === 0;

export const getMonthRange: (min: PlainDate, max: PlainDate) => PlainDate[] = (min, max) => {
  if (isLater(min, max)) {
    throw new Error('Invalid range (max before min)');
  }
  const range = [{ ...min, day: 1 }];
  while (!isSameMonth(range[range.length - 1], max)) {
    range.push(firstOfNextMonth(range[range.length - 1]));
  }
  return range;
};

export const getDayRange: (
  currentValue: PlainDate,
  min: PlainDate,
  max: PlainDate,
) => PlainDate[] = (currentValue, min, max) => {
  let start = 1;
  let end = daysInMonth(currentValue);

  if (isSameMonth(currentValue, min)) {
    start = min.day;
  }

  if (isSameMonth(currentValue, max)) {
    end = max.day;
  }

  return range(start, end).map((day) => ({ ...currentValue, day }));
};

/**
 * Add number of business days to given date
 * @example addBusinessDays(friday, 1) -> monday
 * @example addBusinessDays(saturday, 1) -> monday
 * @example addBusinessDays(thursday, 2) -> monday
 */
export const addBusinessDays = (date: PlainDate, days: number): PlainDate => {
  let result = { ...date };
  do {
    result = addDays(result, 1);
    if (!weekend.includes(getWeekDay(result))) {
      days--;
    }
  } while (days > 0);
  return result;
};

export const toDateString = ({ year, month, day }: PlainDate) =>
  `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
