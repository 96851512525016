import { useCallback } from 'react';

import { useStations } from 'providers/station';

import { Station } from 'types/stations';

export const useGetStation = () => {
  const stations = useStations();
  return useCallback(
    (abbreviation?: string | null): Station | undefined =>
      abbreviation ? stations[abbreviation.toUpperCase()] : undefined,
    [stations],
  );
};
