import dynamic from 'next/dynamic';

import { LOCALE } from 'constants/i18n';

import { createLocalizedProvider } from 'utils/createLocalizedProvider';

const { useProvidedValue: useStations, withProvider: withStationsProvider } =
  createLocalizedProvider(
    'stations',
    {
      [LOCALE.Fi]: dynamic(() => import('./StationsProvider_fi')),
      [LOCALE.En]: dynamic(() => import('./StationsProvider_en')),
      [LOCALE.Sv]: dynamic(() => import('./StationsProvider_sv')),
    },
    {
      [LOCALE.Fi]: () => import('data/stations-fi.json'),
      [LOCALE.En]: () => import('data/stations-en.json'),
      [LOCALE.Sv]: () => import('data/stations-sv.json'),
    },
  );

export { useStations, withStationsProvider };
