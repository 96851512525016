import classNames from 'classnames';
import { ForwardedRef, HTMLAttributes, createElement, forwardRef } from 'react';

import styles from './Col.module.css';

type Props = {
  element?: keyof JSX.IntrinsicElements;

  /**
   * 1-12
   */
  lg?: number;
  /**
   * 1-12
   */
  md?: number;
  /**
   * 1-12
   */
  sm?: number;
  /**
   * 1-12
   */
  col?: number;

  /**
   * 1-12
   */
  mdOffset?: number;
} & HTMLAttributes<'div'>;

const ColComponent = (
  { element, col, sm, md, lg, mdOffset, className, style, ...props }: Props,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  return createElement(element || 'div', {
    ...props,
    ref,
    style: {
      ...style,
      '--col-xs': col,
      '--col-sm': sm,
      '--col-md': md,
      '--col-lg': lg,
      '--col-md-offset': mdOffset,
    },
    className: classNames(className, styles.col),
  });
};

export const Col = forwardRef(ColComponent);

Col.displayName = 'Col';
