import {
  ComponentPropsWithRef,
  ComponentType,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

import { LOCALE } from 'constants/i18n';

export type Provider<T> = React.ComponentType<{
  children: (providedValue: T) => React.ReactElement;
}>;

export const createLocalizedProvider = <T,>(
  name: string,
  providers: Record<LOCALE, Provider<T>>,
  dataLoaders: Record<LOCALE, () => Promise<{ default: T }>>,
) => {
  const context = createContext<T | null>(null);

  const useProvidedValue = () => {
    const value = useContext(context);
    if (!value) {
      throw new Error(`Provider missing: ${name}`);
    }
    return value;
  };

  const withProvider = <C extends ComponentType<any>>(Component: C) => {
    const WrappedComponent = (props: ComponentPropsWithRef<C>) => {
      const { locale } = useIntl();
      const [initialLocale] = useState(locale);
      const Provider = providers[initialLocale];
      const [data, setData] = useState<T | null>(null);

      useEffect(() => {
        const loadData = async () => {
          const { default: loadedData } = await dataLoaders[locale]();
          setData(loadedData);
        };

        if (initialLocale !== locale) {
          loadData();
        } else {
          setData(null);
        }
      }, [initialLocale, locale]);

      return (
        <Provider>
          {(providedValue) => (
            <context.Provider value={data || providedValue}>
              <Component {...props} />
            </context.Provider>
          )}
        </Provider>
      );
    };

    WrappedComponent.displayName = `WrappedComponent(${name})`;

    return WrappedComponent as C;
  };

  return { useProvidedValue, withProvider, context };
};
