import { LineItemProduct } from '@vrfi/payment-summary';
import { AttributeCode, Service } from '@vrfi/service-attribute-mapper';

import {
  MultiTicketType,
  PassengerType,
  ProductType,
  SeatClass,
  SeatPosition,
  SeatProduct,
  TrainType,
  VehicleType,
} from 'backend/types.codegen';

import {
  BookingMessage,
  GenericMessage,
  LocalizationKey,
  MultiTicketMessage,
  PassengerTypeLongInfoMessage,
  PassengerTypeMessage,
  PassengerTypeShortInfoMessage,
  ProductTypeMessage,
  ReceiptTypeMessage,
  SeatMessage,
  SeatServiceDescriptionsMessage,
  SeriesTicketProductNameMessage,
  ServicesMessage,
  TrainTypeMessage,
  VehicleTypeMessage,
} from 'constants/i18n.messages';

import { CorporateTicketType } from 'types/products';
import { Maybe } from 'types/utils';

import { PET_SEAT_ATTRIBUTES, PET_SECTION_SEAT_ATTRIBUTES } from 'constants/journey';
import { fromEntries, getValue } from './object';

const seatClasses: Record<string, SeatMessage | undefined> = {
  [SeatClass.Eco]: SeatMessage.SEAT_CLASS_ECO,
  [SeatClass.Extra]: SeatMessage.SEAT_CLASS_EXTRA,
  [SeatClass.UpstairsRestaurantWagon]: SeatMessage.SEAT_CLASS_UPSTAIRS_RESTAURANT_WAGON,
};

const seatProducts: Record<SeatProduct, SeatMessage> = {
  [SeatProduct.EcoClassSeat]: SeatMessage.SEAT_CLASS_ECO,
  [SeatProduct.ExtraClassSeat]: SeatMessage.SEAT_CLASS_EXTRA,
  [SeatProduct.SingleExtraClassSeat]: SeatMessage.SEAT_CLASS_SINGLE_EXTRA,
  [SeatProduct.SingleSeatEco]: SeatMessage.SEAT_CLASS_SINGLE_ECO,
  [SeatProduct.SingleSeatUpstairsRestaurantWagon]: SeatMessage.SEAT_CLASS_SINGLE_RESTAURANT_WAGON,
  [SeatProduct.SeatUpstairsRestaurantWagon]: SeatMessage.SEAT_CLASS_UPSTAIRS_RESTAURANT_WAGON,
};

const seatPositions: Record<SeatPosition, SeatMessage> = {
  [SeatPosition.Aisle]: SeatMessage.SEAT_POSITION_AISLE,
  [SeatPosition.Middle]: SeatMessage.SEAT_POSITION_MIDDLE,
  [SeatPosition.Window]: SeatMessage.SEAT_POSITION_WINDOW,
  [SeatPosition.Bed]: SeatMessage.SEAT_POSITION_BED,
};

const seatServices: Record<string, ServicesMessage | undefined> = {
  [Service.PET]: ServicesMessage.SEAT_PET,
  [Service.PET_COACH]: ServicesMessage.SEAT_IN_PET_WAGON,
  [Service.PLAY_AREA]: ServicesMessage.SEAT_IN_PLAY_WAGON,
  [Service.WHEELCHAIR_PLACE]: ServicesMessage.SEAT_WHEELCHAIR,
  [Service.OPPOSITE]: ServicesMessage.SEAT_OPPOSITE,
  [Service.FAMILY_COMPARTMENT]: ServicesMessage.SEAT_FAMILY_COMPARTMENT,
  [Service.WORKING_COMPARTMENT]: ServicesMessage.SEAT_WORKING_COMPARTMENT,
  [Service.CONFERENCE_COMPARTMENT]: ServicesMessage.SEAT_CONFERENCE_COMPARTMENT,
};

const seatServiceDescriptions: Record<string, SeatServiceDescriptionsMessage | undefined> = {
  [Service.PET]: SeatServiceDescriptionsMessage.SEAT_PET,
  [Service.PET_COACH]: SeatServiceDescriptionsMessage.SEAT_IN_PET_WAGON,
  [Service.PLAY_AREA]: SeatServiceDescriptionsMessage.SEAT_IN_PLAY_WAGON,
  [Service.WHEELCHAIR_PLACE]: SeatServiceDescriptionsMessage.SEAT_WHEELCHAIR,
  [Service.DUETTO_PLUS]: SeatServiceDescriptionsMessage.SEAT_RESTAURANT_WAGON_UPSTAIRS,
  [Service.FAMILY_COMPARTMENT]: SeatServiceDescriptionsMessage.SEAT_FAMILY_COMPARTMENT,
  [Service.WORKING_COMPARTMENT]: SeatServiceDescriptionsMessage.SEAT_WORKING_COMPARTMENT,
  [Service.CONFERENCE_COMPARTMENT]: SeatServiceDescriptionsMessage.SEAT_CONFERENCE_COMPARTMENT,
  [Service.EKSTRA]: SeatServiceDescriptionsMessage.SEAT_EKSTRA,
  [Service.SINGLE_SEAT]: SeatServiceDescriptionsMessage.SINGLE_SEAT,
};

const passengerTypeMessages: Record<PassengerType, [LocalizationKey, LocalizationKey]> = {
  [PassengerType.Adult]: [PassengerTypeMessage.ADULT, PassengerTypeMessage.ADULT_PLURAL],
  [PassengerType.Assistant]: [
    PassengerTypeMessage.ASSISTANT,
    PassengerTypeMessage.ASSISTANT_PLURAL,
  ],
  [PassengerType.Child]: [PassengerTypeMessage.CHILD, PassengerTypeMessage.CHILD_PLURAL],
  [PassengerType.Conscript]: [
    PassengerTypeMessage.CONSCRIPT,
    PassengerTypeMessage.CONSCRIPT_PLURAL,
  ],
  [PassengerType.ConscriptContract]: [
    PassengerTypeMessage.CONSCRIPT,
    PassengerTypeMessage.CONSCRIPT_PLURAL,
  ],
  [PassengerType.Pensioner]: [
    PassengerTypeMessage.PENSIONER,
    PassengerTypeMessage.PENSIONER_PLURAL,
  ],
  [PassengerType.Student]: [PassengerTypeMessage.STUDENT, PassengerTypeMessage.STUDENT_PLURAL],
  [PassengerType.VrEmployee]: [
    PassengerTypeMessage.VR_EMPLOYEE,
    PassengerTypeMessage.VR_EMPLOYEE_PLURAL,
  ],
  [PassengerType.VrEmployeeFamilyMember]: [
    PassengerTypeMessage.VR_EMPLOYEE_FAMILY_MEMBER,
    PassengerTypeMessage.VR_EMPLOYEE_FAMILY_MEMBER_PLURAL,
  ],
  [PassengerType.VrPensioner]: [
    PassengerTypeMessage.VR_PENSIONER,
    PassengerTypeMessage.VR_PENSIONER_PLURAL,
  ],
  [PassengerType.PlEmployee]: [
    PassengerTypeMessage.VR_EMPLOYEE,
    PassengerTypeMessage.VR_EMPLOYEE_PLURAL,
  ],
  [PassengerType.TramEmployee]: [
    PassengerTypeMessage.VR_EMPLOYEE,
    PassengerTypeMessage.VR_EMPLOYEE_PLURAL,
  ],
  [PassengerType.Fip_50Card]: [
    PassengerTypeMessage.FIP_50_CARD,
    PassengerTypeMessage.FIP_50_CARD_PLURAL,
  ],
  [PassengerType.FipFreeCoupon]: [
    PassengerTypeMessage.FIP_FREE_COUPON,
    PassengerTypeMessage.FIP_FREE_COUPON_PLURAL,
  ],
  [PassengerType.SpecialFreePass]: [
    PassengerTypeMessage.SPECIAL_FREE_PASS,
    PassengerTypeMessage.SPECIAL_FREE_PASS_PLURAL,
  ],
  [PassengerType.Replacement]: [
    PassengerTypeMessage.REPLACMENT,
    PassengerTypeMessage.REPLACMENT_PLURAL,
  ],
  [PassengerType.InterrailEurail_1St]: [
    PassengerTypeMessage.INTERRAIL_EURAIL_1ST,
    PassengerTypeMessage.INTERRAIL_EURAIL_1ST_PlURAL,
  ],
  [PassengerType.InterrailEurail_2Nd]: [
    PassengerTypeMessage.INTERRAIL_EURAIL_2ND,
    PassengerTypeMessage.INTERRAIL_EURAIL_2ND_PLURAL,
  ],
  [PassengerType.FdfContract]: [
    PassengerTypeMessage.FDF_CONTRACT,
    PassengerTypeMessage.FDF_CONTRACT_PLURAL,
  ],
};

type AllType = ProductType | string | VehicleType;

type AllKey =
  | keyof typeof ProductTypeMessage
  | keyof typeof ReceiptTypeMessage
  | keyof typeof VehicleTypeMessage;

const typeMap: { [key in AllType]?: { [key in string]: AllKey } } = {
  [LineItemProduct.BerthCabinCemt]: {
    [AttributeCode.PET]: 'BERTH_CABIN_CEMT_PET',
  },
  [LineItemProduct.BerthCabinEdm]: {
    [AttributeCode.PET]: 'BERTH_CABIN_EDM_PET',
    [AttributeCode.ACCESSIBLE_CABIN]: 'BERTH_CABIN_EDM_ACC',
  },
  [LineItemProduct.BerthCabinWithShower]: {
    [AttributeCode.PET]: 'BERTH_CABIN_WITH_SHOWER_PET',
  },
  [LineItemProduct.SeatUpgradeSingleEco]: fromEntries(
    [...PET_SEAT_ATTRIBUTES, ...PET_SECTION_SEAT_ATTRIBUTES].map((attribute) => [
      attribute,
      'SEAT_UPGRADE_SINGLE_ECO_PET',
    ]),
  ),
};

const resolveKey: (
  keySet: Record<string, LocalizationKey | undefined>,
  query: {
    type: AllType;
    count?: number;
    attribute?: string | null;
  },
) => LocalizationKey | undefined = (keySet, { type, attribute, count }) => {
  const isPlural = typeof count !== 'undefined' && count !== 1;

  const key = ((attribute && typeMap[type]?.[attribute]) || type).toLocaleUpperCase();

  const value = (isPlural && getValue(keySet, `${key}_PLURAL`)) || getValue(keySet, key);

  if (!value) {
    console.warn(`Could not find value for key ${key} in key set ${JSON.stringify(keySet)}`);
  }

  return value;
};

export const getProductTypeMessageId: (
  type: ProductType,
  count?: number,
) => LocalizationKey | undefined = (type, count) => resolveKey(ProductTypeMessage, { type, count });

export const getLineItemProductMessageId: (query: {
  type: string;
  attribute?: string | null;
  count?: number;
}) => LocalizationKey | undefined = (query) => {
  return resolveKey(ReceiptTypeMessage, query);
};

const PRODUCT_MESSAGE_RULES: Array<{
  seatClass: SeatProduct;
  attributes?: string[];
  service?: Service;
  messageId: LocalizationKey;
}> = [
  {
    seatClass: SeatProduct.SingleSeatEco,
    service: Service.PET,
    attributes: [...PET_SEAT_ATTRIBUTES, ...PET_SECTION_SEAT_ATTRIBUTES],
    messageId: SeatMessage.SEAT_CLASS_SINGLE_ECO_PET_SEAT,
  },
  {
    seatClass: SeatProduct.EcoClassSeat,
    attributes: PET_SECTION_SEAT_ATTRIBUTES,
    messageId: SeatMessage.SEAT_CLASS_ECO_IN_PET_WAGON,
  },
];

export const getProductMessageId = (
  seatClass: SeatProduct,
  attribute: Maybe<string>,
  service: Maybe<Service>,
): LocalizationKey | undefined => {
  const matchingRule = PRODUCT_MESSAGE_RULES.find(
    (rule) =>
      rule.seatClass === seatClass &&
      ((rule.attributes && attribute && rule.attributes.includes(attribute)) ||
        (rule.service && rule.service === service)),
  );

  if (matchingRule) {
    return matchingRule.messageId;
  }

  if (service) {
    return getSeatServiceMessageId(service);
  }

  return seatProducts[seatClass];
};

export const getSeatClassMessageId: (seatClass: Maybe<SeatClass>) => LocalizationKey = (
  seatClass,
) => (seatClass && seatClasses[seatClass]) || ReceiptTypeMessage.UNKNOWN;

export const getSeatPositionMessageId: (seatPosition: SeatPosition) => LocalizationKey = (
  seatPosition,
) => seatPositions[seatPosition];

const getSeatServiceMessageId: (service: Service) => LocalizationKey | undefined = (service) =>
  seatServices[service];

export const getSeatServiceDescriptionMessageId: (
  service: Service,
) => LocalizationKey | undefined = (service) => seatServiceDescriptions[service];

const infoMap: Record<
  PassengerType,
  { long?: PassengerTypeLongInfoMessage; short?: PassengerTypeShortInfoMessage } | null
> = {
  [PassengerType.Child]: {
    long: PassengerTypeLongInfoMessage.CHILD,
    short: PassengerTypeShortInfoMessage.CHILD,
  },
  [PassengerType.Student]: {
    long: PassengerTypeLongInfoMessage.STUDENT,
    short: PassengerTypeShortInfoMessage.STUDENT,
  },
  [PassengerType.Conscript]: {
    long: PassengerTypeLongInfoMessage.CONSCRIPT,
    short: PassengerTypeShortInfoMessage.CONSCRIPT,
  },
  [PassengerType.Pensioner]: {
    long: PassengerTypeLongInfoMessage.PENSIONER,
    short: PassengerTypeShortInfoMessage.PENSIONER,
  },
  [PassengerType.VrEmployee]: {
    long: PassengerTypeLongInfoMessage.EMPLOYEE,
    short: PassengerTypeShortInfoMessage.EMPLOYEE,
  },
  [PassengerType.TramEmployee]: {
    long: PassengerTypeLongInfoMessage.EMPLOYEE,
    short: PassengerTypeShortInfoMessage.EMPLOYEE,
  },
  [PassengerType.PlEmployee]: {
    long: PassengerTypeLongInfoMessage.EMPLOYEE,
    short: PassengerTypeShortInfoMessage.EMPLOYEE,
  },
  [PassengerType.VrEmployeeFamilyMember]: {
    short: PassengerTypeShortInfoMessage.VR_EMPLOYEE_FAMILY_MEMBER,
  },
  [PassengerType.FdfContract]: {
    long: PassengerTypeLongInfoMessage.FDF_CONTRACT,
    short: PassengerTypeShortInfoMessage.FDF_CONTRACT,
  },
  [PassengerType.Adult]: null,
  [PassengerType.Assistant]: null,
  [PassengerType.FipFreeCoupon]: null,
  [PassengerType.Fip_50Card]: null,
  [PassengerType.InterrailEurail_1St]: null,
  [PassengerType.InterrailEurail_2Nd]: null,
  [PassengerType.Replacement]: null,
  [PassengerType.ConscriptContract]: null,
  [PassengerType.VrPensioner]: null,
  [PassengerType.SpecialFreePass]: null,
};

export const getShortInfoMessageId = (passengerType: PassengerType) =>
  infoMap[passengerType]?.short ?? undefined;

export const getLongInfoMessageId = (passengerType: PassengerType) =>
  infoMap[passengerType]?.long ?? undefined;

const trainTypeTranslationKey = {
  [TrainType.Ic]: TrainTypeMessage.IC,
  [TrainType.Bus]: TrainTypeMessage.BUS,
  [TrainType.Kla]: TrainTypeMessage.KLA,
  [TrainType.Kvl]: TrainTypeMessage.KVL,
  [TrainType.H]: TrainTypeMessage.H,
  [TrainType.Lol]: TrainTypeMessage.LOL,
  [TrainType.Loc]: TrainTypeMessage.LOC,
  [TrainType.Lkb]: TrainTypeMessage.LKB,
  [TrainType.P]: TrainTypeMessage.P,
  [TrainType.Tax]: TrainTypeMessage.TAX,
  [TrainType.Pyo]: TrainTypeMessage.PYO,
  [TrainType.S]: TrainTypeMessage.S,
  [TrainType.Ae]: TrainTypeMessage.AE,
  [TrainType.Tp]: TrainTypeMessage.TP,
  [TrainType.Ic2]: TrainTypeMessage.IC2,
  [TrainType.Hdm]: TrainTypeMessage.HDM,
  [TrainType.Hla]: TrainTypeMessage.HLA,
  [TrainType.Jla]: TrainTypeMessage.JLA,
  [TrainType.Bhs]: TrainTypeMessage.BHS,
  [TrainType.Blv]: TrainTypeMessage.BLV,
  [TrainType.E]: GenericMessage.TRAIN,
  [TrainType.F]: GenericMessage.TRAIN,
  [TrainType.Pl]: GenericMessage.TRAIN,
  [TrainType.V]: GenericMessage.TRAIN,
  [TrainType.Unknown]: GenericMessage.TRAIN,
} satisfies Record<TrainType, LocalizationKey> as Record<string, LocalizationKey | undefined>;

export const getTrainTypeMessageId = (type: string) =>
  trainTypeTranslationKey[type.toLocaleUpperCase()] ?? GenericMessage.TRAIN;

export const getPassengerTypeMessageId = (type: PassengerType, count?: number) => {
  return passengerTypeMessages[type][count === undefined || count === 1 ? 0 : 1];
};

export const getVehicleTypeMessageId = (type: VehicleType) => {
  return resolveKey(VehicleTypeMessage, { type });
};

export const multiTicketLabelMap = {
  COMMUTER: MultiTicketMessage.COMMUTER,
  LONG_DISTANCE_AND_COMMUTER: MultiTicketMessage.LONG_DISTANCE_AND_COMMUTER,
  LONG_DISTANCE: MultiTicketMessage.LONG_DISTANCE_AND_COMMUTER,
} satisfies Record<MultiTicketType & 'LONG_DISTANCE', LocalizationKey>;

export const corporateTicketTypeMap: Record<CorporateTicketType, LocalizationKey> = {
  SINGLE_TICKET: BookingMessage.SINGLE_TICKET,
  SINGLE_TICKET_FLEX: BookingMessage.SINGLE_TICKET_FLEX,
  CORPORATE_TICKET: BookingMessage.CORPORATE_TICKET,
};

export const seriesTicketProductMap: Record<string, SeriesTicketProductNameMessage | undefined> = {
  MULTITICKET_OPEN: SeriesTicketProductNameMessage.MULTITICKET_ECO,
  COMMUTER: SeriesTicketProductNameMessage.COMMUTER,
  MULTITICKET_ECO: SeriesTicketProductNameMessage.MULTITICKET_ECO,
  MULTITICKET_EXTRA: SeriesTicketProductNameMessage.MULTITICKET_EXTRA,
  MULTITICKET_CAMPAIGN: SeriesTicketProductNameMessage.MULTITICKET_ECO,
  MULTITICKET_COMPENSATION_TICKET: SeriesTicketProductNameMessage.MULTITICKET_ECO,
};
